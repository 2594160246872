// Dependencies
import React, { useState, useEffect } from "react";

// Components
import LinkBlock from "./linkBlock";
import Input from "./input";

const Invitations = (props) => {
  const [invitationAttributes, setInvitationAttributes] = useState(props.invitationAttributes);
  const [invitationAttributesValues, setInvitationAttributesValues] = useState(props.invitationAttributes);
  const [isNested, setIsNested] = useState(true);
  const [firstChecked, setFirstChecked] = useState(false);
  
  useEffect(() => {
      //call to get user grants
      var temp = {}
      
      Object.keys(props.invitationAttributes).forEach(function(key){
          temp[key] = {}
          Object.keys(props.invitationAttributes[key]).forEach(function(key_2){
          temp[key][props.invitationAttributes[key][key_2].key] = props.invitationAttributes[key][key_2].value === true ? 1 : 0
        })
      })
      
      setInvitationAttributesValues(temp)
      
  }, [props.invitationAttributes])


  useEffect(() => {
      //call to get user grants
      if (Object.keys(invitationAttributesValues).length > 0){
        setFirstChecked(true)
        checkAllAssets()  
      }
      
  }, [invitationAttributesValues])

  const _allAssets = () => {
    return [
      "all_assets",
      "all_fixed_assets",
      "bank_account",
      "account_receivable",
      "real_estate",
      "direct_investment",
      "vehicle",
      "watch",
      "art",
      "other",
      "life_insurance",
      "financial_assets"
      ]
  }

  const _onlyAssets = () => {
    return [
      "all_fixed_assets",
      "bank_account",
      "account_receivable",
      "real_estate",
      "direct_investment",
      "vehicle",
      "watch",
      "art",
      "other",
      "life_insurance",
      "financial_assets"
      ]
  }

  const _onlyFixedAssets = () => {
    return [
      "real_estate",
      "direct_investment",
      "vehicle",
      "watch",
      "art",
      ]
  }

  const allHaveValueOne = (hash, keys) => {
    for (const key of keys) {
      if (!(key in hash) || hash[key] !== 1) {
        return false;
      }
    }
    return true;
  }

  const checkAllAssets = () => {
    let new_invitation = invitationAttributesValues

    // let result = allHaveValueOne(new_invitation["manage"], _onlyFixedAssets())
    // new_invitation["manage"]["all_fixed_assets"] = result ? 1 : 0

    let result = allHaveValueOne(new_invitation["manage"], _onlyAssets())
    new_invitation["manage"]["all_assets"] = result ? 1 : 0

    // result = allHaveValueOne(new_invitation["read"], _onlyFixedAssets())
    // new_invitation["read"]["all_fixed_assets"] = result ? 1 : 0
    result = allHaveValueOne(new_invitation["read"], _onlyAssets())
    new_invitation["read"]["all_assets"] = result ? 1 : 0

    setInvitationAttributes(new_invitation)
    setInvitationAttributesValues(new_invitation) 
    // props.setInvitationAttributes(new_invitation)
    // props.setInvitationAttributesValues(new_invitation)        
  }

  const _setCheckNew = (element, key) => {
    let new_invitation = invitationAttributesValues
    // let new_invitation_values = invitationAttributesValues;
    if (element.key === "all_permits" || element.key === "all_assets") {
      if (new_invitation && new_invitation[key] && new_invitation[key][element.key]) {
        // remove
        props.invitationAttributes[key].map((checkbox_element, index2) => {
          if(element.key === "all_assets"){
            if(_allAssets().includes(checkbox_element.key)){
              new_invitation[key][checkbox_element.key] = 0  
            }
          } else{
            new_invitation[key][checkbox_element.key] = 0  
          }
          
        })
        if(key === "manage"){
          props.invitationAttributes["read"].map((checkbox_element, index2) => {
            if(element.key === "all_assets"){
              if(_allAssets().includes(checkbox_element.key)){
                new_invitation["read"][checkbox_element.key] = 0
              }
            } else{
              new_invitation["read"][checkbox_element.key] = 0
            }
          })  
        }
      } else {
        // add
        props.invitationAttributes[key].map((checkbox_element, index2) => {
          if(element.key === "all_assets"){
            if(_allAssets().includes(checkbox_element.key)){
              new_invitation[key][checkbox_element.key] = 1
            }
          } else{
            new_invitation[key][checkbox_element.key] = 1
          }
        })
        if(key === "manage"){
          props.invitationAttributes["read"].map((checkbox_element, index2) => {
            
            if(element.key === "all_assets"){
              if(_allAssets().includes(checkbox_element.key)){
                new_invitation["read"][checkbox_element.key] = 1
              }
            } else{
              new_invitation["read"][checkbox_element.key] = 1
            }
          })  
        }
      }
    } else {
      if (element.key === "all_fixed_assets"){
        if (new_invitation && new_invitation[key] && new_invitation[key][element.key]) {
          //Remove permission
          new_invitation[key][element.key] = 0
          new_invitation["manage"][element.key] = 0
          new_invitation[key]["real_estate"] = 0
          new_invitation[key]["direct_investment"] = 0
          new_invitation[key]["vehicle"] = 0
          new_invitation[key]["watch"] = 0
          new_invitation[key]["art"] = 0
          if(key === "manage"){
            new_invitation["read"][element.key] = 0
            new_invitation["read"]["real_estate"] = 0
            new_invitation["read"]["direct_investment"] = 0
            new_invitation["read"]["vehicle"] = 0
            new_invitation["read"]["watch"] = 0
            new_invitation["read"]["art"] = 0
          }
        } else {
          //Add permission
          new_invitation[key][element.key] = 1
          new_invitation[key]["real_estate"] = 1
          new_invitation[key]["direct_investment"] = 1
          new_invitation[key]["vehicle"] = 1
          new_invitation[key]["watch"] = 1
          new_invitation[key]["art"] = 1
          if(key === "manage"){
            new_invitation["read"][element.key] = 1
            new_invitation["read"]["real_estate"] = 1
            new_invitation["read"]["direct_investment"] = 1
            new_invitation["read"]["vehicle"] = 1
            new_invitation["read"]["watch"] = 1
            new_invitation["read"]["art"] = 1
          }
        } 
      }else{
        if (new_invitation && new_invitation[key] && new_invitation[key][element.key]) {
          //Remove permission
          new_invitation[key][element.key] = 0
          new_invitation["manage"][element.key] = 0
          if(key === "manage"){
            new_invitation["read"][element.key] = 0
            new_invitation["read"][element.key] = 0
          }
        } else {
          //Add permission
          new_invitation[key][element.key] = 1
          if(key === "manage"){
            new_invitation["read"][element.key] = 1
          }
        }
        if(key === "manage"){
          let result = allHaveValueOne(new_invitation[key], _onlyAssets())
          new_invitation[key]["all_assets"] = result ? 1 : 0
          new_invitation["read"]["all_assets"] = result ? 1 : 0
          result = allHaveValueOne(new_invitation[key], _onlyFixedAssets())
          new_invitation[key]["all_fixed_assets"] = result ? 1 : 0
          result = allHaveValueOne(new_invitation["read"], _onlyFixedAssets())
          new_invitation["read"]["all_fixed_assets"] = result ? 1 : 0
        } else{
          let result = allHaveValueOne(new_invitation[key], _onlyAssets())
          new_invitation["read"]["all_assets"] = result ? 1 : 0
          result = allHaveValueOne(new_invitation[key], _onlyFixedAssets())
          new_invitation["read"]["all_fixed_assets"] = result ? 1 : 0
        }  
      }
      
    }

    setInvitationAttributes(new_invitation)
    setInvitationAttributesValues(new_invitation) 
    props.setInvitationAttributes(new_invitation)
    props.setInvitationAttributesValues(new_invitation)    
  }

  const _setCheck = (element, key) => {

    
    let new_invitation = props.permissions_selected
    let new_invitation_values = invitationAttributesValues;
    
    if(new_invitation && new_invitation[key] && new_invitation[key].hasOwnProperty(element.key)){
      if(new_invitation[key][element.key] === 1){
        if(element.key === "all_assets"){
          new_invitation[key]["rest_assets"] = 0
          new_invitation[key]["financial_assets"] = 0
        }
        
        new_invitation[key][element.key] = 0
      }
      else{
        new_invitation[key][element.key] = 1
        if(key === "manage"){
          new_invitation["read"][element.key] = 1
        }
        if(element.key === "all_assets"){
          new_invitation[key]["rest_assets"] = 1
          new_invitation[key]["financial_assets"] = 1
        }              
      }
    }
    else{
      new_invitation[key][element.key] = 1
      if(key === "manage"){
        new_invitation_values["read"][element.key] = 1
      }
      if(element.key === "all_assets"){
        new_invitation[key]["rest_assets"] = 1
        new_invitation[key]["financial_assets"] = 1
        if(key === "manage"){
          new_invitation_values["read"]["rest_assets"] = 1
          new_invitation_values["read"]["financial_assets"] = 1
        }  
      }
      
    }

    if(element.key == "financial_assets" || element.key === "rest_assets"){
      if(new_invitation[key]["financial_assets"] === 1 && new_invitation[key]["rest_assets"] === 1) {
        new_invitation[key]["all_assets"] = 1
      }
      else{
        new_invitation[key]["all_assets"] = 0
      }  
    }
    
    if(new_invitation_values && new_invitation_values[key] && new_invitation_values[key].hasOwnProperty(element.key)){
      if(new_invitation_values[key][element.key] === 1){
        if(element.key === "all_assets"){
          new_invitation_values[key]["rest_assets"] = 0
          new_invitation_values[key]["financial_assets"] = 0
        }
        
        new_invitation_values[key][element.key] = 0
      }
      else{
        new_invitation_values[key][element.key] = 1
        if(key === "manage"){
          new_invitation_values["read"][element.key] = 1
        }
        if(element.key === "all_assets"){
          new_invitation_values[key]["rest_assets"] = 1
          new_invitation_values[key]["financial_assets"] = 1
          if(key === "manage"){
            new_invitation_values["read"]["rest_assets"] = 1
            new_invitation_values["read"]["financial_assets"] = 1
          }
        }              
      }
    }
    else{
      new_invitation_values[key][element.key] = 1
      if(element.key === "all_assets"){
        new_invitation_values[key]["rest_assets"] = 1
        new_invitation_values[key]["financial_assets"] = 1  
        if(key === "manage"){
          new_invitation_values["read"]["rest_assets"] = 1
          new_invitation_values["read"]["financial_assets"] = 1
        } 
      }
      
    }

    if(element.key == "financial_assets" || element.key === "rest_assets"){
      if(new_invitation_values[key]["financial_assets"] === 1 && new_invitation_values[key]["rest_assets"] === 1) {
        new_invitation_values[key]["all_assets"] = 1
      }
      else{
        new_invitation_values[key]["all_assets"] = 0
      }  
    } 

    if(element.key !== "all_permits"){
      if(new_invitation_values[key]["all_assets"] === 1 && new_invitation_values[key]["ownership"] === 1){
        new_invitation_values[key]["all_permits"] = 1
        new_invitation[key]["all_permits"] = 1
      }
      else{
        new_invitation_values[key]["all_permits"] = 0
        new_invitation[key]["all_permits"] = 0
      }      
    }  

    if(element.key === "all_permits"){
      if(new_invitation_values[key]["all_permits"] === 1){
        let new_invitation_values_temp = {}

        Object.keys(new_invitation_values[key]).forEach(function(key_inside){ 
          new_invitation_values[key][key_inside] = 1 
        });
        if(key === "manage"){
          Object.keys(new_invitation_values["read"]).forEach(function(key_inside){ 
            new_invitation_values["read"][key_inside] = 1 
          });
         }
        // Object.keys(new_invitation[key]).forEach(function(key_inside){ new_invitation[key][key_inside] = 1 });
      }
      else{
        Object.keys(new_invitation_values[key]).forEach(function(key_inside){ new_invitation_values[key][key_inside] = 0})
        // Object.keys(new_invitation[key]).forEach(function(key_inside){ new_invitation[key][key_inside] = 0});
      }
    }
    setInvitationAttributes(new_invitation_values)
    props.setInvitationAttributes(new_invitation)
    props.setInvitationAttributesValues(new_invitation_values)
  }

  return(
    <>
      <div className="wrapper-invitations flex">
        {
          Object.keys(props.invitationAttributes).map((element, index) => {
            return (
              <div key={"invitation_view_"+index} className="container-invitation">
                <p className="title1" style={{paddingLeft: 16}}>{index === 0 ? props.language && props.language.edit && props.language.edit.toUpperCase() : props.language && props.language.visualize && props.language.visualize.toUpperCase()}</p>
                {
                  props.invitationAttributes[element].map((checkbox_element, index2) => {
                    let id = invitationAttributesValues[element] && invitationAttributesValues[element][checkbox_element.key] ? invitationAttributesValues[element][checkbox_element.key] : "_"
                    let result = invitationAttributesValues[element] && invitationAttributesValues[element].hasOwnProperty(checkbox_element.key) ? (invitationAttributesValues[element][checkbox_element.key] === 1) : checkbox_element.value
                    if(invitationAttributesValues[element]){
                    }

                    let pdL =  checkbox_element.key === "all_permits" || checkbox_element.key === "all_assets" || checkbox_element.key === "liabilities" || checkbox_element.key === "ownership" || checkbox_element.key === "wealth_map"
                      ? 0 // main elements
                      : checkbox_element.key === "financial_assets" ||  checkbox_element.key === "bank_account" || checkbox_element.key === "account_receivable" || checkbox_element.key === "life_insurance" || checkbox_element.key === "all_fixed_assets" || checkbox_element.key === "other"
                        ? 24 // certain elements 
                        : 48 // rest of elements like vehicles, watches and whatever.

                    return(
                      <div style={{paddingLeft: pdL}}>
                        <LinkBlock
                          key={"permission_"+index+"_"+index2+"_"+id + "_" + result }
                          id={checkbox_element.id}
                          className={""}
                          balance={true}
                          date={checkbox_element.label}
                          description=""
                          action={() => _setCheckNew(checkbox_element, element)}
                          checkBox={true}
                          editable={props.editable}
                          isChecked={result}

                        />

                      </div>
                    )
                  })
                }

                {index === 0 && (
                  <p style={{paddingInline: 16, marginTop: 4}}>{
                    props.language_user !== "es-ES" 
                    ? "*Financial Assets cannot be edited in bt360 to avoid duplication of data as they are loaded in Addepar."
                    : "*Los Activos Financieros no se pueden editar en bt360 para que no se dupliquen los datos ya que estos se cargan en Addepar."
                    }
                  </p>
                )}


              </div>

            )
          })
        }
      </div>
    </>
  )
}

export default Invitations
